import React from "react"
import { Link } from "gatsby"
import Typewriter from "../components/typewriter"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheDrip from "../components/theDrip"
import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title={siteTitle} />
      <div>
        <h2>
          <Typewriter
            typetext="I create experiences for the web and beyond."
            delay="3"
          />
        </h2>
        <TheDrip />
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
