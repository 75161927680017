import React, { useEffect } from "react"
import { gsap, Expo, SteppedEase } from "gsap"

function Typewriter(props) {
  const caret = React.createRef(),
    typetext = React.createRef(),
    typecontainer = React.createRef()

  useEffect(() => {
    var color1 = "#333",
      moveBar = () => {
        gsap.set(caret.current, {
          left: gsap.getProperty(typetext.current, "width") + 1,
        })
      }

    gsap
      .timeline({ delay: props.delay || 0 })
      .set(typetext.current, { color: color1, width: 0, opacity: 1 })
      .set(caret.current, {
        left: 1,
        backgroundColor: color1,
        height: gsap.getProperty(typetext.current, "line-height"),
      })
      .set(typecontainer.current, {
        height: gsap.getProperty(typetext.current, "height"),
      })
      .to(
        caret.current,
        {
          duration: 0.1,
          opacity: 0,
          ease: Expo.easeIn,
          yoyo: true,
          repeat: 5,
          repeatDelay: 0.3,
        },
        1
      )
      .to(
        typetext.current,
        {
          duration: 1.1,
          width: gsap.getProperty(typetext.current, "width") + 1,
          ease: SteppedEase.config(14),
          onUpdate: moveBar,
        },
        2.5
      )
      .to(caret.current, {
        duration: 0.25,
        opacity: 0,
        ease: Expo.easeIn,
      })
      .set(typetext.current, {
        width: "auto",
      })
      .timeScale(2)
  }, [])

  return (
    <div className="typing-container" ref={typecontainer}>
      <div className="typing-text" ref={typetext}>
        {props.typetext}
      </div>
      <div className="typing-caret" ref={caret}></div>
    </div>
  )
}

export default Typewriter
