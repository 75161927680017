import * as THREE from "three"
import React, { useEffect } from "react"
import Calc from "../utils/calc"
import Ease from "../utils/ease"
import AxisHelper from "../utils/axis"

function TheDrip() {
  const threeOutput = React.createRef()

  useEffect(() => {
    new Loader(System, threeOutput)
  }, [])

  return <div className="three" ref={threeOutput}></div>
}

class ParticleBase {
  constructor(config, system, loader) {
    this.system = system
    this.loader = loader

    this.calc = this.loader.calc
    this.ease = this.loader.ease

    this.group = config.group
    this.x = config.x
    this.y = config.y
    this.z = config.z
    this.size = config.size
    this.color = config.color
    this.opacity = config.opacity

    this.createMesh()
  }

  createMesh() {
    this.geometry = this.system.sphereGeometry

    this.material = new THREE.MeshBasicMaterial({
      color: this.color,
      transparent: true,
      opacity: this.opacity,
      depthTest: false,
      precision: "lowp",
    })

    this.mesh = new THREE.Mesh(this.geometry, this.material)

    this.mesh.position.x = this.x
    this.mesh.position.y = this.y
    this.mesh.position.z = this.z

    this.mesh.scale.set(this.size, this.size, this.size)

    this.group.add(this.mesh)
  }

  reset() {}
}

class SystemBase {
  constructor(loader) {
    this.loader = loader

    this.calc = this.loader.calc
    this.ease = this.loader.ease

    this.sphereGeometry = new THREE.SphereBufferGeometry(1, 16, 16)
    this.boxGeometry = new THREE.BoxBufferGeometry(1, 1, 1)
    this.center = new THREE.Vector3()

    this.particles = []
    this.particleGroup = new THREE.Object3D()
    this.particleGroup.scale.set(0.0001, 0.0001, 0.0001)

    this.loader.scene.add(this.particleGroup)

    this.entering = true
    this.enterProgress = 0
    this.enterRate = 0.015

    this.exiting = false
    this.exitProgress = 0
    this.exitRate = 0.01
    this.duration = Infinity
  }

  update() {
    let i = this.particles.length
    while (i--) {
      this.particles[i].update()
    }

    if (this.entering && this.enterProgress < 1) {
      this.enterProgress += this.enterRate * this.loader.deltaTimeNormal
      if (this.enterProgress > 1) {
        this.enterProgress = 1
        this.entering = false
      }
      let scale = this.ease.inOutExpo(this.enterProgress, 0, 1, 1)
      this.particleGroup.scale.set(scale, scale, scale)
    }

    /* if (!this.exiting && this.loader.elapsedMilliseconds > this.duration) {
      this.exiting = true
    }

    if (this.exiting) {
      this.exitProgress += this.exitRate * this.loader.deltaTimeNormal
      if (this.exitProgress >= 1 && !this.loader.completed) {
        this.exitProgress = 1
        this.loader.complete()
      }
    }*/
  }

  replay() {
    this.particleGroup.scale.set(0.0001, 0.0001, 0.0001)

    let i = this.particles.length
    while (i--) {
      this.particles[i].reset()
    }

    this.entering = true
    this.enterProgress = 0

    this.exiting = false
    this.exitProgress = 0
  }
}

class System extends SystemBase {
  constructor(loader) {
    super(loader)

    this.duration = 6000

    this.size = 35
    this.cols = 25
    this.rows = 25

    this.drops = []
    this.ripples = []

    this.dropTick = 15
    this.dropTickMin = 200 //15
    this.dropTickMax = 200 //25

    for (let col = 0; col < this.cols; col++) {
      for (let row = 0; row < this.rows; row++) {
        let x = this.calc.map(
          col,
          0,
          this.cols - 1,
          -this.size / 2,
          this.size / 2
        )
        let y = 0
        let z = this.calc.map(
          row,
          0,
          this.rows - 1,
          -this.size / 2,
          this.size / 2
        )

        this.particles.push(
          new Particle(
            {
              group: this.particleGroup,
              x: x,
              y: y,
              z: z,
              size: 0.01,
              color: 0xdb0f4f,
              opacity: 0.01,
            },
            this,
            this.loader
          )
        )
      }
    }

    this.reset()
  }

  reset() {
    this.tick = 0
    this.finalDrop = false
    this.setCamera()

    let i = this.drops.length
    while (i--) {
      this.drops[i].progress = 1
      this.drops[i].update(i)
    }

    let j = this.ripples.length
    while (j--) {
      this.ripples[j].destroy(i)
    }

    this.drops.length = 0
    this.ripples.length = 0
  }

  setCamera() {
    if (!this.loader.isGrid) {
      this.loader.cameraBaseY = 25
      this.loader.camera.position.y = this.loader.cameraBaseY
      this.loader.camera.lookAt(this.center)
    }
  }

  createDrop(x, y, z, strength) {
    this.drops.push(
      new Drop(
        {
          array: this.drops,
          group: this.particleGroup,
          /*x:
            x === undefined ? this.calc.rand(-this.size / 2, this.size / 2) : x,
          y: y === undefined ? this.calc.rand(15, 20) : y,
          z:
            z === undefined ? this.calc.rand(-this.size / 2, this.size / 2) : z,*/
          x: this.size / 4,
          y: 50,
          z: this.size / 4,
          size: 0.1,
          color: 0xdb0f4f,
          opacity: 0,
          strength: strength,
        },
        this,
        this.loader
      )
    )
  }

  updateDrops() {
    let i = this.drops.length
    while (i--) {
      this.drops[i].update(i)
    }
  }

  createRipple(x, z, strength) {
    this.ripples.push(
      new Ripple(
        {
          array: this.ripples,
          group: this.particleGroup,
          x: x,
          y: -0.1,
          z: z,
          strength: strength,
        },
        this,
        this.loader
      )
    )
  }

  updateRipples() {
    let i = this.ripples.length
    while (i--) {
      this.ripples[i].update(i)
    }
  }

  replay() {
    super.replay()
    this.reset()
  }

  update() {
    super.update()

    if (this.tick >= this.dropTick) {
      this.createDrop()
      this.dropTick = this.calc.randInt(this.dropTickMin, this.dropTickMax)
      this.tick = 0
    }

    this.updateDrops()
    this.updateRipples()

    let i = this.particles.length
    while (i--) {
      let j = this.ripples.length
      while (j--) {
        let particle = this.particles[i]
        let ripple = this.ripples[j]
        let influence = ripple.getInfluenceVector(particle.base)
        influence.setX(0)
        influence.setZ(0)
        particle.velocity.add(influence)
      }
    }

    this.particleGroup.rotation.x =
      Math.cos(this.loader.elapsedMilliseconds * 0.0005) * 0.1
    this.particleGroup.rotation.y =
      Math.PI * 0.25 + Math.sin(this.loader.elapsedMilliseconds * 0.0005) * -0.2

    this.tick += this.loader.deltaTimeNormal

    if (this.exiting && !this.loader.isOrbit && !this.loader.isGrid) {
      if (!this.finalDrop) {
        this.createDrop(0, 20, 0, 20)
        this.finalDrop = true
      }
      this.loader.camera.position.y =
        this.loader.cameraBaseY -
        this.ease.inExpo(this.exitProgress, 0, 1, 1) * this.loader.cameraBaseY
      this.loader.camera.position.z =
        this.loader.cameraBaseZ -
        this.ease.inExpo(this.exitProgress, 0, 1, 1) * this.loader.cameraBaseZ
      this.loader.camera.lookAt(this.center)
    }
  }
}

class Drop {
  constructor(config, system, loader) {
    this.system = system
    this.loader = loader

    this.calc = this.loader.calc
    this.ease = this.loader.ease

    this.array = config.array
    this.group = config.group
    this.x = config.x
    this.y = config.y
    this.z = config.z
    this.size = config.size
    this.color = config.color
    this.opacity = config.opacity
    this.strength = config.strength

    this.yBase = config.y

    this.progress = 0
    this.rate = 0.015

    this.createMesh()
  }

  createMesh() {
    this.geometry = this.system.boxGeometry

    this.material = new THREE.MeshBasicMaterial({
      color: this.color,
      transparent: true,
      opacity: this.opacity,
      depthTest: false,
      precision: "lowp",
    })

    this.mesh = new THREE.Mesh(this.geometry, this.material)

    this.mesh.position.x = this.x
    this.mesh.position.y = this.y
    this.mesh.position.z = this.z

    this.mesh.scale.set(this.size, this.size, this.size)

    this.group.add(this.mesh)
  }

  update(i) {
    this.progress += this.rate * this.loader.deltaTimeNormal
    this.mesh.position.y =
      this.yBase - this.ease.inExpo(this.progress, 0, 1, 1) * this.yBase
    this.mesh.scale.set(
      this.size,
      this.size + this.size * 16 * this.ease.inExpo(this.progress, 0, 1, 1),
      this.size
    )
    this.mesh.material.opacity = this.ease.inExpo(this.progress, 0, 1, 1)

    if (this.progress >= 1) {
      this.geometry.dispose()
      this.material.dispose()
      this.group.remove(this.mesh)
      this.array.splice(i, 1)
      this.system.createRipple(
        this.mesh.position.x,
        this.mesh.position.z,
        this.strength
      )
    }
  }
}

class Particle extends ParticleBase {
  constructor(config, system, loader) {
    super(config, system, loader)

    this.base = new THREE.Vector3(config.x, config.y, config.z)
    this.velocity = new THREE.Vector3(0, 0, 0)

    this.lerpFactor = 0.3
    this.dampFactor = 0.3
  }

  update() {
    let scale = 0.075 + Math.abs(this.velocity.y) / 25
    this.mesh.scale.set(scale, scale, scale)

    let opacity = 0.15 + Math.abs(this.velocity.y) / 1
    this.mesh.material.opacity = this.calc.clamp(opacity, 0.15, 1)

    this.velocity.y += (this.base.y - this.mesh.position.y) * this.lerpFactor
    this.velocity.multiplyScalar(this.dampFactor)
    this.mesh.position.add(this.velocity)
  }
}

class Ripple {
  constructor(config, system, loader) {
    this.system = system
    this.loader = loader

    this.calc = this.loader.calc
    this.ease = this.loader.ease

    this.array = config.array
    this.group = config.group
    this.sphere = new THREE.Sphere(
      new THREE.Vector3(config.x, config.y, config.z),
      0
    )
    this.strength = config.strength ? config.strength : this.calc.rand(7, 12)
    this.threshold = this.calc.rand(4, 8)
    this.growth = this.calc.rand(0.1, 0.3)
    this.life = 1
    this.decay = this.calc.rand(0.01, 0.02)
    this.influence = new THREE.Vector3()
    this.geometry = new THREE.CircleGeometry(1, 36)
    this.geometry.vertices.shift()
    this.geometry.applyMatrix(new THREE.Matrix4().makeRotationX(Math.PI / 2))

    this.material = new THREE.LineBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 1,
      depthTest: false,
      precision: "lowp",
    })
    this.mesh = new THREE.LineLoop(this.geometry, this.material)
    this.mesh.position.x = this.sphere.center.x
    this.mesh.position.y = 0
    this.mesh.position.z = this.sphere.center.z
    this.group.add(this.mesh)
  }

  getInfluenceVector(vec) {
    this.influence.set(0, 0, 0)
    let distance = this.sphere.distanceToPoint(vec)

    if (distance <= this.threshold) {
      let ease = this.ease.inOutSine(
        this.threshold - distance,
        0,
        1,
        this.threshold
      )
      let power = this.strength * ease * this.life
      this.influence.addVectors(vec, this.sphere.center).multiplyScalar(power)
    }

    return this.influence
  }

  update(i) {
    this.sphere.radius += this.growth * this.life * this.loader.deltaTimeNormal
    this.life -= this.decay * this.loader.deltaTimeNormal

    this.mesh.position.y = (1 - this.life) * -2
    let newScale = 0.001 + this.sphere.radius
    this.mesh.scale.set(newScale, newScale, newScale)
    this.mesh.material.opacity = this.life / 3

    if (this.life <= 0) {
      this.destroy(i)
    }
  }

  destroy(i) {
    this.geometry.dispose()
    this.material.dispose()
    this.group.remove(this.mesh)
    this.array.splice(i, 1)
  }
}

class Loader {
  constructor(System, threeOutput) {
    this.calc = new Calc()
    this.ease = new Ease()

    this.dom = {
      html: document.documentElement,
      container: document.querySelector(".loader"),
      timescaleWrap: document.querySelector(".timescale-wrap"),
      timescaleRange: document.querySelector(".timescale-range"),
      timescaleValue: document.querySelector(".timescale-value"),
      replayButton: document.querySelector(".replay-animation"),
      debugButton: document.querySelector(".icon--debug"),
    }

    this.dom.html.classList.add("loading")

    this.completed = false
    this.raf = null

    //this.setupDebug()
    this.setupTime()
    this.setupScene()
    this.setupCamera()
    this.setupRenderer(threeOutput)
    this.setupControls()
    this.setupHelpers()
    this.listen()
    this.onResize()

    this.system = new System(this)
    this.loop()
  }

  /*setupDebug() {
    this.isDebug = location.hash.indexOf("debug") > 0
    this.isGrid = location.hash.indexOf("grid") > 0
    this.isOrbit = location.hash.indexOf("orbit") > 0

    this.debugHash = ""

    if (this.isDebug) {
      this.isGrid = true
      this.isOrbit = true
      this.debugHash += "debug"
      this.dom.html.classList.add("is-debug")
    } else {
      this.debugHash += this.isGrid ? "grid" : ""
      this.debugHash += this.isOrbit ? "orbit" : ""
    }

    if (this.debugHash) {
      ;[].slice
        .call(document.querySelectorAll(".demo"))
        .forEach((elem, i, arr) => {
          elem.setAttribute(
            "href",
            `${elem.getAttribute("href")}#${this.debugHash}`
          )
        })
    }
  }*/

  setupTime() {
    this.timescale = 1
    this.clock = new THREE.Clock()
    this.deltaTimeSeconds = this.clock.getDelta() * this.timescale
    this.deltaTimeMilliseconds = this.deltaTimeSeconds * 1000
    this.deltaTimeNormal = this.deltaTimeMilliseconds / (1000 / 60)
    this.elapsedMilliseconds = 0
  }

  setupScene() {
    this.scene = new THREE.Scene()
  }

  setupCamera() {
    this.camera = new THREE.PerspectiveCamera(70, 0, 0.0001, 10000)

    this.cameraBaseX = this.isGrid ? -20 : 0
    this.cameraBaseY = this.isGrid ? 15 : 0
    this.cameraBaseZ = this.isGrid ? 20 : 30

    this.camera.position.x = this.cameraBaseX
    this.camera.position.y = this.cameraBaseY
    this.camera.position.z = this.cameraBaseZ
  }

  setupRenderer(threeOutput) {
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
    })

    document.body
      .getElementsByClassName("three")[0]
      .append(this.renderer.domElement)
  }

  setupControls() {
    if (this.isOrbit) {
      this.controls = new THREE.OrbitControls(
        this.camera,
        this.renderer.domElement
      )
      this.controls.enableDamping = true
      this.controls.dampingFactor = 0.2
      this.controls.enableKeys = false

      this.dom.timescaleWrap.style.visibility = "visible"
    }
  }

  setupHelpers() {
    var demoNum = 1

    if (this.isGrid) {
      this.gridOpacityMap = [
        0.4, // 1
        0.2, // 2
        0.2, // 3
        0.2, // 4
        0.1, // 5
        0.2, // 6
        0.1, // 7
        0.1, // 8
      ]
      this.gridHelper = new THREE.GridHelper(300, 60, 0xffffff, 0xffffff)
      this.gridHelper.material.transparent = true
      this.gridHelper.material.opacity = this.gridOpacityMap[demoNum - 1]
      this.scene.add(this.gridHelper)

      this.axisOpacityMap = [
        1, // 1
        0.6, // 2
        0.6, // 3
        0.6, // 4
        0.3, // 5
        0.6, // 6
        0.3, // 7
        0.3, // 8
      ]
      this.axisHelper = new AxisHelper(150, this.axisOpacityMap[demoNum - 1])
      this.scene.add(this.axisHelper)

      this.camera.lookAt(new THREE.Vector3())
    }
  }

  update() {
    this.deltaTimeSeconds = this.clock.getDelta()
    if (this.diffTime) {
      this.deltaTimeSeconds -= this.diffTime
      this.diffTime = 0
    }
    this.deltaTimeSeconds *= this.timescale
    this.deltaTimeMilliseconds = this.deltaTimeSeconds * 1000
    this.deltaTimeNormal = this.deltaTimeMilliseconds / (1000 / 60)
    this.elapsedMilliseconds += this.deltaTimeMilliseconds

    this.system.update()

    if (this.isOrbit) {
      this.controls.update()
    }
  }

  render() {
    this.renderer.render(this.scene, this.camera)
  }

  listen() {
    window.addEventListener("resize", e => this.onResize(e))

    /*this.dom.replayButton.addEventListener("click", e =>
      this.onReplayButtonClick(e)
    )
    this.dom.debugButton.addEventListener("click", e =>
      this.onDebugButtonClick(e)
    )*/

    if (this.isOrbit) {
      this.dom.timescaleRange.addEventListener("change", e =>
        this.onTimescaleRangeChange(e)
      )
      this.dom.timescaleRange.addEventListener("mousemove", e =>
        this.onTimescaleRangeChange(e)
      )
    }

    this.hidden = null
    this.visibilityChange = null
    if (typeof document.hidden !== "undefined") {
      this.hidden = "hidden"
      this.visibilityChange = "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      this.hidden = "msHidden"
      this.visibilityChange = "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      this.hidden = "webkitHidden"
      this.visibilityChange = "webkitvisibilitychange"
    }
    if (
      typeof document.addEventListener === "undefined" ||
      typeof document.hidden === "undefined"
    ) {
    } else {
      window.addEventListener(this.visibilityChange, e =>
        this.onVisibilityChange(e)
      )
    }
  }

  replay() {
    document.documentElement.classList.remove("completed")
    document.documentElement.classList.add("loading")

    this.camera.position.x = this.cameraBaseX
    this.camera.position.y = this.cameraBaseY
    this.camera.position.z = this.cameraBaseZ

    this.timescale = 1
    this.deltaTimeSeconds = 1 / 60
    this.deltaTimeMilliseconds = this.deltaTimeSeconds * 1000
    this.deltaTimeNormal = this.deltaTimeMilliseconds / (1000 / 60)
    this.elapsedMilliseconds = 0
    this.blurTime = 0
    this.diffTime = 0
    this.focusTime = 0

    this.system.replay()
    this.completed = false
    this.clock.start()
    this.loop()
  }

  complete() {
    if (this.isOrbit || this.isGrid) {
      return
    }
    setTimeout(() => {
      this.clock.stop()
      cancelAnimationFrame(this.raf)
    }, 600)
    this.completed = true
    this.dom.html.classList.remove("loading")
    this.dom.html.classList.add("completed")
  }

  onResize() {
    this.width = window.innerWidth - 100
    this.height = window.innerHeight - 100

    this.dpr = window.devicePixelRatio > 1 ? 2 : 1

    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()

    this.renderer.setPixelRatio(this.dpr)
    this.renderer.setSize(this.width, this.height)
  }

  onReplayButtonClick(e) {
    e.preventDefault()
    this.replay()
  }

  /*onDebugButtonClick(e) {
    e.preventDefault()
    let baseURL = window.location.href.split("#")[0]
    if (this.isDebug) {
      if (history) {
        history.pushState("", document.title, window.location.pathname)
      } else {
        location.hash = ""
      }
      location.href = baseURL
    } else {
      location.href = `${baseURL}#debug`
    }
    location.reload()
  }*/

  onTimescaleRangeChange(e) {
    this.timescale = parseFloat(this.dom.timescaleRange.value)
    this.dom.timescaleValue.innerHTML = this.timescale.toFixed(1)
  }

  onVisibilityChange(e) {
    if (document.hidden) {
      this.blurTime = Date.now()
    } else {
      this.focusTime = Date.now()
      if (this.blurTime) {
        this.diffTime = (this.focusTime - this.blurTime) / 1000
      }
    }
  }

  loop() {
    this.update()
    this.render()
    this.raf = window.requestAnimationFrame(() => this.loop())
  }
}

export default TheDrip
